import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { fetchHeaderData } from "../store/layout";
import intlTelInput from "intl-tel-input";
import Image from "../components/elements/Image";
import "intl-tel-input/build/css/intlTelInput.css";
import { get_test_data } from "../actions/testimonials";
import { logindata, signup } from "../store/auth";
import { ToastContainer, toast } from "react-toastify";
import { numberWithCommas, onlyUnique } from "../helpers";
import { removetocart } from "../store/cart";
import { loginuser } from "../actions/login";
import LoginModal from "./loginModal";
import AddToCartModal from "../components/pagesections/ProgramDetails/AddToCartModal";
import { autoLogin } from "../actions/dashboard";
import LMS_URL from "../config/lms";
const DefaultHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("slug");
  const [scroll, setScroll] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [countData, setCountData] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const [emailLogin, setEmailLogin] = useState(false);
  const [username, setUsername] = useState("");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [hireFrom, setHireFrom] = useState("");
  const [address, setAddress] = useState("");
  const [passwordShow, showPassword] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [subCategories, setSubcategories] = useState([]);
  const [isRegister, setRegister] = useState(false);
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);

  const phoneInput = useRef<HTMLInputElement>(null);
  const url = useLocation();
  const isLandingPage = url.pathname.split("/")[1];

  const headerData = useSelector(
    (state: any) => state.layoutData.headerData.data
  );

  const loginData = useSelector((state: any) => state.authData.authData);
  const signUpData = useSelector((state: any) => state.authData.signUpData);

  //const loading = useSelector((state: any) => state.authData.loading);

  const cart = useSelector((state: any) => state.counter.cartData);
  const cartData = onlyUnique(cart);
  useEffect(() => {
    if (loginData.code == 200) {
      toast.success(loginData.message);
      localStorage.setItem("slug", loginData.data.slug);
      window.location.reload();
    } else {
      toast.error(loginData.message);
    }
  }, [loginData]);

  const count = useSelector((state: any) => state.counter.value);

  if (headerData && Object.keys(headerData).length > 0) {
  }

  // Fetch header data
  useEffect(() => {
    dispatch(fetchHeaderData());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  useEffect(() => {
    setCountData(count);
  }, [count]);

  useEffect(() => {
    if (phoneInput.current !== null) {
      intlTelInput(phoneInput.current, {});
    }
  }, [phoneInput]);

  /* function openSearch() {
    const myOverlay = document.getElementById('myOverlay') as HTMLElement;
    myOverlay.style.display = 'block';
  } */

  function closeSearch() {
    const myOverlay = document.getElementById("myOverlay") as HTMLElement;
    myOverlay.style.display = "none";
  }

  const logout = () => {
    localStorage.removeItem("loginData");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    window.location.href = "/";
  };

  const menuToggle = () => {
    const body = document.getElementById("body") as HTMLElement;
    setIsMenuOpened(!isMenuOpened);
    if (isMenuOpened) {
      body.classList.add("menuIsOpened");
    } else {
      body.classList.remove("menuIsOpened");
    }
  };

  const searchToggle = () => {
    const body = document.getElementById("body") as HTMLElement;
    setIsSearchOpened(!isSearchOpened);
    if (isSearchOpened) {
      body.classList.add("searchIsOpened");
    } else {
      body.classList.remove("searchIsOpened");
    }
  };

  const keywordSearch = (id: any) => {
    navigate(`/program-detail/${id}`);
    setShowModal(false);
  };

  const searchData = (e: any) => {
    const keyWordData = get_test_data(
      `/search/result?keyword=${e.target.value}`,
      "get"
    )
      .then((res) => {
        if (res && res.code === 200) {
          setData(res.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => console.log(error));
    if (e.target.value !== "") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const login = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("wstoken", "bb2abfaaaed78967785951e5f17f0826");
    formData.append("wsfunction", "local_user_login");
    formData.append("moodlewsrestformat", "json");
    formData.append("username", username);
    formData.append("password", password);
    const loginData = await loginuser(formData);
    if (loginData.data.userfound) {
      toast.success("Successfully logged in");
      setLoading(false);
      localStorage.setItem("loginData", JSON.stringify(loginData.data));
    } else {
      toast.error(loginData.message);
      setLoading(false);
      window.location.reload();
    }
  };
  const [formOpen, setFormOpen] = useState(false);
  const register = () => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("registration_type", "2");
    formData.append("mobile", phone);
    formData.append("company", company);
    formData.append("designation", designation);
    formData.append("experience", experience);
    formData.append("qualification", qualification);
    formData.append("hear_from", qualification);
    formData.append("address", address);
    dispatch(signup(formData));
  };

  useEffect(() => {
    if (headerData && Array.isArray(headerData) && headerData.length > 0) {
      setSubcategories(headerData[0]?.sub_category);
    }
  }, [headerData && Array.isArray(headerData) && headerData.length > 0]);

  const getSubMenu = (ID: any) => {
    const getSub =
      headerData &&
      headerData.length > 0 &&
      headerData.find((item: any) => {
        return item.ID === ID;
      });

    if (getSub?.sub_category?.length > 0) {
      setSubcategories(getSub.sub_category);
    }
  };
  const [isHovering, setIsHovering] = useState(false);
  const [isCartHovering, setCartIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleMouseOver2 = () => {
    setCartIsHovering(true);
  };
  const handleMouseOut2 = () => {
    setCartIsHovering(false);
  };

  const clickDashboard = async () => {
    const username = localStorage.getItem("username") || "";
    const password = localStorage.getItem("password") || "";
    const url = `${LMS_URL}/local/participant/ws/autologin3.php?username=${username}&password=${password}`;
    window.location.href = url;
  };

  useEffect(() => {
    if (cartData.length > 0) {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    } else {
      localStorage.setItem("cartData", JSON.stringify([]));
    }
  }, [cartData]);

  var totalPrice =
    localStorage.getItem("cartData") &&
    cartData.reduce(
      (sum: 0, item: any) =>
        sum + Number(item?.sale_price ? item.sale_price : item.program_fees),
      0
    );
  return (
    <>
      <div className="search-container position-absolute bg-white border-bottom">
        <div className="container-fluid">
          <div className="d-flex">
            <div className="flex-grow-1">
              <div className="input-group input-group-lg mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type to search..."
                  aria-label="Type to search..."
                  aria-describedby="searchbtn"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="searchbtn"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="close-search"
                onClick={() => searchToggle()}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
          </div>
          <div className="popular-searches">
            <h6>Hot Searches</h6>
            <NavLink className="popular-search-items" to="#">
              Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Faculty
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Cyber Security
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              University Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Online Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Accounts Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Faculty
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Cyber Security
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              University Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Online Programs
            </NavLink>
            <NavLink className="popular-search-items" to="#">
              Accounts Programs
            </NavLink>
          </div>
        </div>
      </div>

      <header className="header-mobile position-relative">
        <div className="container-fluid px-0">
          <div className="d-flex justify-content-between align-items-center">
            <a className="logo-mobile" href="/">
              <div className="img-fluid mobile-logo-img d-block d-sm-none">
                <Image
                  src={window.location.origin + "/images/main-logo-alt.png"}
                  alt={"GT Learning"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={""}
                />
              </div>
              <div className="img-fluid mobile-logo-img d-none d-sm-block">
                <Image
                  src={window.location.origin + "/images/main-logo-alt.png"}
                  alt={"GT Learning"}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={""}
                />
              </div>
            </a>
            <div className="header-link-options d-flex flex-row position-relative">
              {/* <button
                className="header-link d-flex flex-column justify-content-center align-items-center h-100 search-link"
                type="button"
                onClick={() => searchToggle()}
              >
                <i className="fa fa-search"></i>
                <span>Search</span>
              </button> */}
              {/* {cartData?.length} */}
              {isLandingPage !== "lp" && (
                <div
                  className="header-link d-flex flex-column justify-content-center align-items-center h-100 cursor-pointer"
                  onClick={() => {
                    cartData.length > 0 && navigate("/checkout");
                    handleMouseOver2();
                    setCartIsHovering(true);
                  }}
                >
                  <i className="fa fa-shopping-cart"></i>
                  <span>Cart({cartData?.length})</span>
                </div>
              )}
              {!localStorage.getItem("username") && isLandingPage !== "lp" && (
                <Link
                  to={"/login"}
                  className="header-link d-flex flex-column justify-content-center align-items-center h-100"
                  // onClick={() => setFormOpen(true)}
                >
                  <i className="fa fa-sign-in"></i>
                  <span>Login</span>
                </Link>
              )}

              {localStorage.getItem("username") &&
                localStorage.getItem("password") && (
                  <>
                    <a
                      className="header-link d-flex flex-column justify-content-center align-items-center h-100"
                      onClick={() => clickDashboard()}
                    >
                      <i className="fa fa-tachometer"></i>
                      <span>Dashboard</span>
                    </a>
                    <a
                      className="header-link d-flex flex-column justify-content-center align-items-center h-100"
                      onClick={() => logout()}
                    >
                      <i className="fa fa-sign-out"></i>
                      <span>Logout</span>
                    </a>
                  </>
                )}

              <button
                className="hamburger-menu"
                type="button"
                onClick={() => menuToggle()}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu-container transition">
        <div className="mobile-menu-header">
          <h4 className="mb-0">Programs</h4>
          <button
            className="close-menu"
            type="button"
            onClick={() => menuToggle()}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
        <div className="mobile-menu-body">
          <div className="my-mega">
            <div className="mega-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-sm-4 col-md-3 py-4 categories-bg border-end pe-0">
                    <h5>Categories</h5>

                    <div className="mn-menu-block">
                      <ul
                        className="nav nav-tabs flex-row"
                        id="myTab"
                        role="tablist"
                      >
                        {headerData &&
                          Object.values(headerData || {}).length > 0 &&
                          headerData &&
                          headerData?.map((header: any, i: any) => (
                            <li
                              className="nav-item"
                              role="presentation"
                              key={header.ID}
                              onClick={() => {
                                setActiveTab(header.ID);
                                getSubMenu(header.ID);
                              }}
                            >
                              <button
                                className={
                                  "nav-link " + (i === 0 ? "active" : "")
                                }
                                id={`tab` + header.ID}
                                data-bs-toggle="tab"
                                data-bs-target={`tab-panel` + header.ID}
                                type="button"
                                role="tab"
                                aria-controls={`tab-panel` + header.ID}
                                aria-selected="true"
                              >
                                {header?.title || ""}
                              </button>
                            </li>
                          ))}
                      </ul>
                      <NavLink to="/listing" className="viw-all-bt mt-3">
                        View all
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-9">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          {subCategories &&
                            Array.isArray(subCategories) &&
                            subCategories?.map((item: any, inx) => (
                              <div
                                className="col-md-6 border-end py-4"
                                key={item.ID}
                              >
                                <div className="sub-menu-block">
                                  <h5 className="purple-color">
                                    {item.title}
                                    {/* <span className='badge text-bg-danger'>
                                                      Live
                                                    </span> */}
                                  </h5>
                                  <ul>
                                    {Object.values(item.program_list || {}).map(
                                      (program: any) => (
                                        <li key={program.program_id}>
                                          <NavLink
                                            to={
                                              "/program-detail/" + program.slug
                                            }
                                            onClick={() => {
                                              setIsHovering(false);
                                              menuToggle();
                                            }}
                                          >
                                            {program.title}
                                          </NavLink>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                  <a
                                    href={
                                      window.location.origin +
                                      "/listing?type=" +
                                      (inx === 0 ? 68 : 69)
                                    }
                                    className="viw-all-bt"
                                    onClick={() => menuToggle()}
                                  >
                                    View all
                                  </a>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      {/* <div
                        className="tab-pane fade"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                Instructor Led Online Programs{" "}
                                <span className="badge text-bg-danger">
                                  Live
                                </span>
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    ACCA Qualification - Association of
                                    Chartered Certified Accountants
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    ACCA DipIFRS - Online Live Training
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    Certification in Financial Modelling &
                                    Valuation Online Live
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    Certificate in Blockchain Online Live
                                  </NavLink>
                                </li>
                              </ul>
                              <NavLink to="/listing" onClick={() => menuToggle()} className="viw-all-bt">
                                View all
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                e Learning programs
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    e Learning programs
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Finance for Non-Finance Professionals E
                                    Learning Course
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certification in Data Analytics E - Learning
                                  </NavLink>
                                </li>
                              </ul>
                              <NavLink
                                to="/listing"
                                className="viw-all-bt mt-3"  onClick={() => menuToggle()}
                              >
                                View all
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="contact-tab-pane"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                e Learning programs
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    e Learning programs
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Finance for Non-Finance Professionals E
                                    Learning Course
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certification in Data Analytics E - Learning
                                  </NavLink>
                                </li>
                              </ul>
                              <NavLink to="/listing" className="viw-all-bt"  onClick={() => menuToggle()}>
                                View all
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                Instructor Led Online Programs{" "}
                                <span className="badge text-bg-danger">
                                  Live
                                </span>
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    ACCA Qualification - Association of
                                    Chartered Certified Accountants
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    ACCA DipIFRS - Online Live Training
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certification in Financial Modelling &
                                    Valuation Online Live
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certificate in Blockchain Online Live
                                  </NavLink>
                                </li>
                              </ul>
                              <NavLink to="/listing" className="viw-all-bt"  onClick={() => menuToggle()}>
                                View all
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="blockchains-tab-pane"
                        role="tabpanel"
                        aria-labelledby="blockchains-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                Instructor Led Online Programs{" "}
                                <span className="badge text-bg-danger">
                                  Live
                                </span>
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    ACCA Qualification - Association of
                                    Chartered Certified Accountants
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    ACCA DipIFRS - Online Live Training
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certification in Financial Modelling &
                                    Valuation Online Live
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing"  onClick={() => menuToggle()}>
                                    Certificate in Blockchain Online Live
                                  </NavLink>
                                </li>
                              </ul>
                              <NavLink to="/listing" className="viw-all-bt" onClick={() => menuToggle()}>
                                View all
                              </NavLink>
                            </div>
                          </div>
                          <div className="col-12 py-3">
                            <div className="sub-menu-block">
                              <h5 className="purple-color">
                                e Learning programs
                              </h5>
                              <ul>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    e Learning programs
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    Finance for Non-Finance Professionals E
                                    Learning Course
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/listing" onClick={() => menuToggle()}>
                                    Certification in Data Analytics E - Learning
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                            <NavLink to="/listing" className="viw-all-bt" onClick={() => menuToggle()}>
                              View all
                            </NavLink>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="main-header header-desktop">
        <div className="header-navbar">
          <nav
            className={
              "navbar navbar-expand-lg navbar-light navbar-2 " +
              (scroll && location.pathname.split("/")[1] !== "program-detail"
                ? "isFixed"
                : "")
            }
          >
            <div className="container-xl d-block">
              <div className="navbar-wrap d-flex align-items-center">
                <div className="navbar-left d-flex flex-shrink-0 align-items-center">
                  <Link
                    to={"/"}
                    className="navbar-brand d-inline-flex pb-0 align-items-center"
                  >
                    <Image
                      src={window.location.origin + "/images/main-logo-alt.png"}
                      alt={"GT Learning"}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"img-fluid desktop-logo"}
                    />
                  </Link>
                  <div
                    className="collapse navbar-collapse d-inline-block"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav me-auto ps-md-4">
                      <li
                        className={
                          "nav-item dropdown dropdown-mega position-static my-mega " +
                          (isHovering ? "show" : "hide")
                        }
                        // onMouseOver={handleMouseOver}
                        // onMouseOut={handleMouseOut}
                      >
                        {" "}
                        {isLandingPage !== "lp" && (
                          <a
                            className="nav-link dropdown-toggle program-nav-link"
                            href="#!"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="fa fa-bars"></i> Programmes
                          </a>
                        )}
                        <div className="dropdown-menu border">
                          <div className="mega-content px-4">
                            <div className="container">
                              <div className="row">
                                <div className="col-12 col-sm-4 col-md-3 py-4 categories-bg border-end pe-0">
                                  <h5>Categories</h5>
                                  <div className="mn-menu-block">
                                    <ul
                                      className="nav nav-tabs flex-column"
                                      id="myTab"
                                      role="tablist"
                                    >
                                      {headerData &&
                                        Object.values(headerData || {}).length >
                                          0 &&
                                        headerData &&
                                        headerData?.map(
                                          (header: any, i: any) => (
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                              key={header.ID}
                                              onClick={() => {
                                                setActiveTab(header.ID);
                                                getSubMenu(header.ID);
                                              }}
                                            >
                                              <button
                                                className={
                                                  "nav-link " +
                                                  (i === 0 ? "active" : "")
                                                }
                                                id={`tab` + header.ID}
                                                data-bs-toggle="tab"
                                                data-bs-target={
                                                  `tab-panel` + header.ID
                                                }
                                                type="button"
                                                role="tab"
                                                aria-controls={
                                                  `tab-panel` + header.ID
                                                }
                                                aria-selected="true"
                                              >
                                                {" "}
                                                {header?.title || ""}
                                              </button>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                    <NavLink
                                      to="/listing"
                                      className="viw-all-bt mt-3"
                                    >
                                      View all
                                    </NavLink>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-9">
                                  <div
                                    className="tab-content"
                                    id="myTabContent"
                                  >
                                    {/* {Object.values(headerData || {})
                                    .sort((a:any, b:any) => b.ID - a.ID).map(
                                      (header: any, i) => (
                                        <div
                                          className={`tab-pane fade show ${header.ID === activeTab ? 'active' : !activeTab && !i ? 'active' : ''}`}
                                          id={'tab-panel' + header.ID}
                                          role='tabpanel'
                                          aria-labelledby={
                                            `tab` + header.ID
                                          }
                                          tabIndex={0}
                                          key={header.ID}
                                        > */}
                                    <div className="row">
                                      {subCategories &&
                                        Array.isArray(subCategories) &&
                                        subCategories?.map((item: any, inx) => (
                                          <div
                                            className="col-md-6 border-end py-4"
                                            key={item.ID}
                                          >
                                            <div className="sub-menu-block">
                                              <h5 className="purple-color">
                                                {item.title}
                                                {/* <span className='badge text-bg-danger'>
                                                      Live
                                                    </span> */}
                                              </h5>
                                              <ul>
                                                {Object.values(
                                                  item.program_list || {}
                                                ).map((program: any) => (
                                                  <li key={program.program_id}>
                                                    <NavLink
                                                      to={
                                                        "/program-detail/" +
                                                        program.slug
                                                      }
                                                      onClick={() =>
                                                        setIsHovering(false)
                                                      }
                                                    >
                                                      {program.title}
                                                    </NavLink>
                                                  </li>
                                                ))}
                                              </ul>
                                              <a
                                                href={
                                                  window.location.origin +
                                                  "/listing?type=" +
                                                  (inx === 0 ? 68 : 69)
                                                }
                                                className="viw-all-bt"
                                              >
                                                View all
                                              </a>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                    {/* </div> */}
                                    {/* )
                                    )}  */}

                                    {/* <div
                                      className='tab-pane fade show active'
                                      id='home-tab-pane'
                                      role='tabpanel'
                                      aria-labelledby='home-tab'
                                      tabIndex={0}
                                    >
                                      <div className='row'>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              Instructor Led Online Programs{' '}
                                              <span className='badge text-bg-danger'>
                                                Live
                                              </span>
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA Qualification -
                                                  Association of Chartered
                                                  Certified Accountants
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA DipIFRS - Online Live
                                                  Training
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Financial
                                                  Modelling & Valuation Online
                                                  Live
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certificate in Blockchain
                                                  Online Live
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              e Learning programs
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  e Learning programs
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Finance for Non-Finance
                                                  Professionals E Learning
                                                  Course
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Data
                                                  Analytics E - Learning
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <div
                                      className='tab-pane fade'
                                      id='profile-tab-pane'
                                      role='tabpanel'
                                      aria-labelledby='profile-tab'
                                      tabIndex={0}
                                    >
                                      <div className='row'>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              Instructor Led Online Programs{' '}
                                              <span className='badge text-bg-danger'>
                                                Live
                                              </span>
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA Qualification -
                                                  Association of Chartered
                                                  Certified Accountants
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA DipIFRS - Online Live
                                                  Training
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Financial
                                                  Modelling & Valuation Online
                                                  Live
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certificate in Blockchain
                                                  Online Live
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              e Learning programs
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  e Learning programs
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Finance for Non-Finance
                                                  Professionals E Learning
                                                  Course
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Data
                                                  Analytics E - Learning
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt mt-3'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className='tab-pane fade'
                                      id='contact-tab-pane'
                                      role='tabpanel'
                                      aria-labelledby='contact-tab'
                                      tabIndex={0}
                                    >
                                      <div className='row'>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              e Learning programs
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  e Learning programs
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Finance for Non-Finance
                                                  Professionals E Learning
                                                  Course
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Data
                                                  Analytics E - Learning
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              Instructor Led Online Programs{' '}
                                              <span className='badge text-bg-danger'>
                                                Live
                                              </span>
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA Qualification -
                                                  Association of Chartered
                                                  Certified Accountants
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA DipIFRS - Online Live
                                                  Training
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Financial
                                                  Modelling & Valuation Online
                                                  Live
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certificate in Blockchain
                                                  Online Live
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className='tab-pane fade'
                                      id='blockchains-tab-pane'
                                      role='tabpanel'
                                      aria-labelledby='blockchains-tab'
                                      tabIndex={0}
                                    >
                                      <div className='row'>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              Instructor Led Online Programs{' '}
                                              <span className='badge text-bg-danger'>
                                                Live
                                              </span>
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA Qualification -
                                                  Association of Chartered
                                                  Certified Accountants
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  ACCA DipIFRS - Online Live
                                                  Training
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Financial
                                                  Modelling & Valuation Online
                                                  Live
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certificate in Blockchain
                                                  Online Live
                                                </NavLink>
                                              </li>
                                            </ul>
                                            <NavLink
                                              to='/listing'
                                              className='viw-all-bt'
                                            >
                                              View all
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className='col-md-6 border-end py-4'>
                                          <div className='sub-menu-block'>
                                            <h5 className='purple-color'>
                                              e Learning programs
                                            </h5>
                                            <ul>
                                              <li>
                                                <NavLink to='/listing'>
                                                  e Learning programs
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Finance for Non-Finance
                                                  Professionals E Learning
                                                  Course
                                                </NavLink>
                                              </li>
                                              <li>
                                                <NavLink to='/listing'>
                                                  Certification in Data
                                                  Analytics E - Learning
                                                </NavLink>
                                              </li>
                                            </ul>
                                          </div>
                                          <NavLink
                                            to='/listing'
                                            className='viw-all-bt'
                                          >
                                            View all
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="navbar-right flex-grow-1 ms-md-4 ms-0">
                  <div className="row">
                    <div className="col-md">
                      <div className="header-search-bar">
                        {isLandingPage !== "lp" && (
                          <>
                            <i className="fa fa-search"></i>
                            <input
                              className="form-control"
                              placeholder="Type to search..."
                              value={keyword}
                              onChange={(e) => setKeyword(e.target.value)}
                              onKeyUp={(e) => searchData(e)}
                            />
                          </>
                        )}
                        {keyword && (
                          <datalist id="datalistOptions">
                            <option value="ACCA">ACCA</option>
                            <option value="Accountants">Accountants</option>
                            <option value="Cyber Security">
                              Cyber Security
                            </option>
                            <option value="Personal Finance">
                              Personal Finance
                            </option>
                            <option value="GT Courses">GT Courses</option>
                            <option value="GT Faculty">GT Faculty</option>
                          </datalist>
                        )}
                        {showModal && (
                          <div className="search-list shadow-sm bg-white">
                            {showModal &&
                              data &&
                              data.map((item: any, index) => (
                                <div
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  onClick={() => keywordSearch(item?.slug)}
                                >
                                  <span>
                                  {item?.title}
                                  <span style={{ color: "red",}}>
                                   &nbsp; 
                                   ({item?.categories[1]=="Instructor Led Online Programme (Live)"?"Online Live":"E-Learning"})
                                  </span> </span>
                                  {/* {item?.} */}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-auto text-end">
                      <ul className="navbar-nav ms-auto me-0 right-nav">
                        <li
                          onMouseOver={handleMouseOver2}
                          onMouseOut={handleMouseOut2}
                          className={
                            "nav-item cart dropdown" +
                            (isCartHovering ? " show" : "")
                          }
                        >
                          {isLandingPage !== "lp" && (
                            <div
                              className="btn btn btn-link text-decoration-none dropdown-toggle"
                              onClick={() => {
                                setCartIsHovering(true);
                              }}
                            >
                              <i className="fa fa-shopping-cart"></i>
                              <span>Cart</span>({cartData?.length})
                            </div>
                          )}
                          {cartData.length !== 0 && (
                            <ul className="dropdown-menu cart-dropdown-menu shadow-sm">
                              {Array.isArray(cartData) &&
                                cartData.map((item: any, index: any) => (
                                  <li key={index}>
                                    <a
                                      className="dropdown-item d-flex"
                                      href="#"
                                    >
                                      <span className="course-imgg flex-shrink-0">
                                        <img
                                          className="img-fluid"
                                          src={item?.featured_image}
                                          alt=""
                                        />
                                      </span>
                                      <span className="course-name-det flex-grow-1 d-flex">
                                        <div>
                                          <h6>{item?.title}</h6>
                                          <p className="mb-0">
                                            {item?.program_fees && (
                                              <span className="d-inline-block">
                                                INR{" "}
                                                {numberWithCommas(
                                                  item?.sale_price
                                                    ? item.sale_price
                                                    : item?.program_fees
                                                )}
                                              </span>
                                            )}

                                            {item?.sale_price && (
                                              <span className="d-inline-block">
                                                <s
                                                  style={{
                                                    color: "gray",
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  INR{" "}
                                                  {numberWithCommas(
                                                    item?.program_fees
                                                  )}
                                                </s>
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                        <span
                                          style={{
                                            height: "25px",
                                            width: "45px",
                                          }}
                                          onClick={() =>
                                            dispatch(
                                              removetocart(item?.program_id)
                                            )
                                          }
                                          className="text-white bg-danger d-flex align-items-center justify-content-center"
                                        >
                                          X
                                        </span>
                                      </span>
                                    </a>
                                  </li>
                                ))}
                              <li className="text-center total">
                                <h6>
                                  Total : INR {numberWithCommas(totalPrice)}
                                  {/* <del className="small text-muted">54,900</del>  */}
                                </h6>
                                <Link
                                  className="btn btn-dark d-block w-100"
                                  to="/checkout"
                                >
                                  Checkout
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>

                        {localStorage.getItem("username") &&
                          localStorage.getItem("password") && (
                            <>
                              <li className="nav-item login MyDashboard me-2">
                                <button
                                  className="btn btn-outline-dark btn-md"
                                  type="button"
                                  onClick={() => clickDashboard()}
                                >
                                  My Dashboard
                                </button>
                              </li>

                              <li className="nav-item login">
                                <button
                                  className="btn btn btn-dark"
                                  type="button"
                                  onClick={() => logout()}
                                >
                                  LOG OUT
                                </button>
                              </li>
                            </>
                          )}

                        {!localStorage.getItem("username") &&
                          isLandingPage !== "lp" && (
                            <li className="nav-item login">
                              <Link to={"/login"}>
                                <button
                                  className="btn btn btn-dark"
                                  type="button"
                                  // onClick={() => setFormOpen(true)}
                                >
                                  LOGIN
                                </button>
                              </Link>
                            </li>
                          )}

                        <Link
                          to={"/"}
                          className="navbar-brand d-inline-flex pb-0 align-items-center ps-3"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/images/grant-thornton.png"
                            }
                            alt={"Grant Thornton"}
                            width={"100%"}
                            height={"100%"}
                            effect={"blur"}
                            classes={"img-fluid desktop-logo"}
                          />
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <div id="myOverlay" className="overlay">
        <div className="closebtn" onClick={closeSearch} title="Close Overlay">
          ×
        </div>
        <div className="overlay-content">
          <form action="">
            <input
              type="text"
              placeholder="Search.."
              name="search"
              list="datalistOptions"
              id="searchDataList"
            />
            {/* <datalist id="datalistOptions">
              <option value="ACCA">ACCA</option>
              <option value="Accountants">Accountants</option>
              <option value="Cyber Security">Cyber Security</option>
              <option value="Personal Finance">Personal Finance</option>
              <option value="GT Courses">GT Courses</option>
              <option value="GT Faculty">GT Faculty</option>
            </datalist> */}
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end leftside-login"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {!isRegister && isLandingPage !== "lp" && (
            <>
              <h5 id="offcanvasRightLabel" className="all_heading">
                Login
              </h5>
              {/* <span>or</span>{" "}
              <a
                href="#!"
                className="purple-color create-btn"
                onClick={() => setRegister(true)}
              >
                Create Your Account
              </a> */}
              <form>
                {!emailLogin && (
                  <div className="input-group py-4">
                    <div className="mb-2">
                      <input
                        //ref={phoneInput}
                        type="tel"
                        placeholder="Email"
                        name="email"
                        onChange={(e) => setUsername(e.target.value)}
                        className="form-control  w-100 rounded"
                        required
                      />
                    </div>
                    <div className="mb-2">
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control  w-100 rounded"
                        required
                      />
                    </div>
                  </div>
                )}

                {emailLogin && (
                  <div className="input-group py-4">
                    <input
                      type="text"
                      placeholder="Enter your email"
                      name="email"
                      id="email"
                      className="form-control  w-100 rounded"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                )}

                {passwordShow && (
                  <div className="input-group py-4">
                    <input
                      type="password"
                      placeholder="Enter your password"
                      name="password"
                      id="password"
                      className="form-control  w-100 rounded"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                )}
              </form>
              <div className="row justify-content-between align-items-center mt-2">
                <div className="col-auto">
                  {" "}
                  <button className="btn btn btn-dark" onClick={() => login()}>
                    {loading && isLandingPage !== "lp" && (
                      <>
                        <i className="fa fa-sync fa-spin"></i>
                      </>
                    )}{" "}
                    &nbsp;{""}LOGIN
                  </button>
                </div>
                {/* {!emailLogin && (
                  <div className="col-auto">
                    {" "}
                    <Link
                      to="#"
                      className=" create-btn"
                      onClick={() => setEmailLogin(true)}
                    >
                      Continue With Email
                    </Link>{" "}
                  </div>
                )} */}
              </div>
            </>
          )}

          {isRegister && (
            <>
              <h5 id="offcanvasRightLabel" className="all_heading">
                Register
              </h5>
              <span>or</span>{" "}
              <a
                href="#!"
                className="purple-color create-btn"
                onClick={() => setRegister(false)}
              >
                Login
              </a>
              <form>
                <div className="input-group py-4">
                  <input
                    type="tel"
                    placeholder="Enter your username"
                    name="username"
                    id="username"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    id="email"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your password"
                    name="password"
                    id="password"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your mobile"
                    name="phone"
                    id="phone"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your mobile"
                    name="company"
                    id="company"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your designation"
                    name="experience"
                    id="experience"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setDesignation(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your experience"
                    name="experience"
                    id="experience"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setExperience(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your qualification"
                    name="qualification"
                    id="qualification"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setQualification(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Hear From"
                    name="hear_from"
                    id="hear_from"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setHireFrom(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group py-4">
                  <input
                    type="text"
                    placeholder="Enter your address"
                    name="address"
                    id="address"
                    className="form-control  w-100 rounded"
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
              </form>
              <div className="row justify-content-between align-items-center mt-2">
                <div className="col-auto">
                  {" "}
                  <Link
                    className="btn btn btn-dark"
                    to="#"
                    onClick={() => register()}
                  >
                    {loading && (
                      <>
                        <i className="fa fa-sync fa-spin"></i>
                      </>
                    )}{" "}
                    &nbsp;{""}Sign Up
                  </Link>
                </div>
                {!isRegister && (
                  <div className="col-auto">
                    {" "}
                    <Link
                      to="#"
                      className=" create-btn"
                      onClick={() => setEmailLogin(true)}
                    >
                      Continue With Email
                    </Link>{" "}
                  </div>
                )}
              </div>
            </>
          )}
          <hr className="mt-5 mb-4" />
          <div className="link-group">
            <a
              className="btn-link text-decoration-none small text-muted pe-2"
              href={LMS_URL + "login/forgot_password.php"}
            >
              Forgot Password?
            </a>
          </div>
          <small className="text-muted foot-text position-fixed">
            Having trouble? Please contact{" "}
            <Link to="#" className="">
              help@gtacademy.in
            </Link>{" "}
            for further support.
          </small>
        </div>
      </div>

      <div
        className="modal fade"
        id="forgotPassword"
        tabIndex={-1}
        aria-labelledby="forgotPasswordLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-6" id="forgotPasswordLabel">
                Forgot Password?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-5">
              <form action="" className="form">
                <div className="mb-2">
                  <label htmlFor="enterEmailID" className="form-label fw600">
                    Your Email/Mobile No.
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="enterEmailID"
                    placeholder="Enter Email ID/Mobile No."
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-dark w-100 btn-lg mb-3"
                >
                  Reset Password
                </button>
                <p className="small text-muted">
                  Enter your email address/mobile to receive OTP to reset your
                  password in case you don't remember your password
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      {formOpen && <LoginModal setFormOpen={setFormOpen} formOpen={formOpen} />}

      <ToastContainer style={{ width: "400px" }} />
    </>
  );
};

export default DefaultHeader;
